import React, { Component } from "react";
import "antd/dist/antd.css";
import { Card } from "antd";

const { Meta } = Card;

export class OurProjects extends Component {
  render() {
    return (
      <div id="OurProjects" >
        <div className="container">
          <div className="section-title">
            <h2 className="text-center">Our Projects</h2>
            <div className="box-shadow color-bar1">
            <p>
              Billing: Billing is an online application which can be used by any
              business men; it generates the Invoice for any business in less
              time. It manages customer’s data and defines various levels of
              access control to the application. User can generate and print the
              invoice based upon the products purchased by the customer. It can
              be used by large and small organizations. Technologies used are
              Asp.net 2.0 and SQL Server 2008. To buy these products kindly
              contact us.
            </p>

            <p>
              Customer Subscription: Customer Subscription is a user friendly
              and online application which can be used by business men for their
              business for customer subscription. It maintains user subscription
              automatically notifies whenever a user is added to a subscription
              it also intimates to the customer by sending an email, before
              subscription expiry date and it also indicates if account is
              disabled due to expiration. This application can be used for
              maintaining newspaper subscriptions, magazine subscription or any
              kind of online subscription. Application can be customized based
              on client’s requirement. It offers additional Home like online
              payment through customer subscription management system can be
              included.
            </p>
            </div>
          </div>
          <div className="row">
            {this.props.data
              ? this.props.data.map((d, i) => (
                  <div key={`${d.name}-${i}`} className="col-md-4">
                    {" "}
                    <Card
                      hoverable
                      style={{ width: 350 }}
                      cover={<img src={d.img} alt="" />}
                    >
                      <Meta
                        title={<h3>{d.name}</h3>}
                        description={
                          <a href={d.text} target="_blank"  rel="noopener noreferrer">
                            Click Here
                          </a>
                        }
                      />
                    </Card>
                    <i className={d.icon}></i>
                    <div className="service-desc"></div>
                  </div>
                ))
              : "loading"}
          </div>
        </div>
      </div>
    );
  }
}

export default OurProjects;
