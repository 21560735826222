import React, { Component } from "react";

export class About extends Component {
  render() {
    return (
      <div id="Abouts">
        <div className="container">
          <div className="section-title">
            <h2 className="text-center">About</h2>
            <div className="box-shadow">
              <p>
                "Asoft Technologies is an Indian based IT Company Located in
                Bangalore. Our core expertise is fast custom implementation of
                the feature rich websites with strong functional component and
                administration backend. Asoft Technologies India also offer
                website redesign and website up gradation OurProjects to small
                businesses across the world. We offer website re-design
                OurProjects that are cost effective and fit to individual client
                needs, so that they get the best out of online marketing.Apart
                from web redesigning OurProjects we also offer constant website
                maintenance OurProjects for businesses that are already have a
                website that needs to be updated regularly. Asoft Technologies
                India can provide unique Company Website Design and fluent
                website themes that promote your Company and give your website a
                unique look that will be remembered by your visitors, promoting
                your WWW is the second most important function of our Company.
                We have dedicated team to provide you and your business complete
                support who are experts in the recent technologies like REACTJS,
                ANGULARJS, ASP.NET, JAVA, AJAX, PHP, JAVASCRIPT, and SQL SERVER
                for database. "
              </p>

             
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
