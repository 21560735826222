import React, { Component } from "react";

export class Header extends Component {
  render() {
    return (
      <header id="head">
        <div className="intro">
        <br></br> <br></br> <br></br>
          <img src="img/projects/k.jpg" className="img-responsive" alt="MAIN PAGE" />
        </div>
      </header>
    );
  }
}

export default Header;