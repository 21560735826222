import React, { Component } from "react";

export class WebSolutions extends Component {
  render() {
    return (
      <div id="WebSolutions">
        <div className="container">
          <div className="section-title">
            <h2  className="text-center">WebSolutions</h2>
            <div className="box-shadow">
            <p>
              Web designing is all about creating a stunning website,
              streamlined with user interactivity, well-versed with
              information-rich content. Asoft Technologies believes in "The
              first impression is often the last and the best impression". Hence
              need for a website arises as it creates long lasting impressions
              in the minds of the users. Leader in - Web Portals, Web 2.0
              website design, Enterprise Solutions, E-Commerce Applications,
              Community Portals.
            </p>

            <p>
              We have the ability to create websites of different complexity
              that can include today’s latest technologies. Asoft Technologies a
              Product Development Company offers custom website solutions, web
              programming, Web application development, Windows application
              development, ERP implementations, E commerce website design and
              development in affordable ranges as per client needs and
              requirement.{" "}
            </p>
            <p>
              Promote your online business, engage new customers, increase your
              profits Asoft Provides to its customers SMS marketing and email
              marketing to grow up their business. Know more about Sms and Email
              Marketing
            </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WebSolutions;
