import React, { Component } from 'react'
import Navigation from './components/navigation';
import Home from './components/Home';
import OurProjects from './components/OurProjects';
import Testimonials from './components/testimonials';
import Contact from './components/contact';
import JsonData from './data/data.json';
import WebSolutions from './components/WebSolutions';
import Header from './components/header';
import  { About } from './components/About';

export class App extends Component {
  state = {
    landingPageData: {},
  }
  getlandingPageData() {
    this.setState({landingPageData : JsonData})
  }

  componentDidMount() {
    this.getlandingPageData();
  }

  render() {
    return (
      <div>
        <Navigation />
        <Header data={this.state.landingPageData.Home}/>
        <Home data={this.state.landingPageData.Home} />
        <About/>
        <OurProjects  data={this.state.landingPageData.OurProjects}/>
        <WebSolutions data={this.state.landingPageData.WebSolutions} />
        <Testimonials  />
        <Contact data={this.state.landingPageData.Contact} />
      </div>
    )
  }
}

export default App;
